@import "abstracts/_abstracts";
.AppFooter {
	background: var(--colorFooterAccent);
	padding-top: var(--spaceXl);
	margin-top: var(--spaceXl);
	position: relative;
	color: var(--colorThemeAccent);

	&-image {
		position: absolute;
		inset: 0;
		left: 0;
		width: 50%;
		clip: rect(0, auto, auto, 0);
		overflow: hidden;
		z-index: var(--layerNullZIndex);

		&:after {
			display: block;
			content: '';
			position: absolute;
			inset: 0;
			background-image: linear-gradient(270deg, var(--colorFooterAccent) 0%, var(--colorFooterAccentAlpha) 100%);
		}

		img {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	h2 {
		font-size: 2rem;
		line-height: 1.25;
		font-weight: 700;
		color: var(--colorThemeAccent);
		margin-bottom: 0;

		@include media('<=sm') {
			font-size: 1.5rem;
		}
	}

	&-frame {
		display: flex;
		flex-wrap: wrap;
		gap: 2rem 4rem;
		position: relative;
	}

	&-info {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		gap: 1rem 2rem;
		border-top: 1px solid var(--colorFooterAccentLight);
		padding: 1.5rem 0;
		font-size: 1rem;
		line-height: 1.5;

		&-col {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 1rem 2rem;
		}
	}

	&-main {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		a {
			color: inherit;
		}
	}

	&-contacts {
		display: flex;
		flex-flow: column;
		gap: 1rem;

		@include media('<=xxs') {
			width: 100%;
		}
	}

	&-contact {
		display: flex;
		gap: 0.5rem;
		font-size: 1rem;
		line-height: 1.5;
		font-weight: 600;

		@include media('<=sm') {
			font-size: var(--textSm);
		}

		svg {
			width: 1.5rem;
			height: 1.5rem;
			color: var(--colorBrand);
		}

		span, a {
			flex: 1;
			font-weight: 500;
		}
	}

	&-socials {
		display: flex;
		flex-wrap: wrap;

		a {
			text-decoration: none;
			transition: var(--animationBase);
			margin-right: var(--spaceXxs);

			&:last-child {
				margin-right: 0;
			}

			@include on-event {
				text-decoration: none;
				color: var(--colorBrand);
			}

			svg {
				width: 1.2em;
				height: 1.2em;
			}
		}
	}

	&-navs {
		width: 48%;
		@include media('<=xxs') {
			width: 100%;
		}
	}

	&-form {
		font-size: .85em;
		flex: 1;

		@include media('<=md') {
			width: 100%;
			font-size: 1em;
		}

		h2 {
			font-weight: 700;
		}
	}

	&-copyFrame {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&-copy {
		padding: var(--spaceMd) 0;
		background: var(--colorContrastBg);
		color: text-contrast(var(--colorContrastBg));
		font-size: var(--textXs);
		position: relative;

		a {
			color: inherit;
		}

		p {
			display: flex;
			align-items: center;
			margin: 0;

			&:first-child {
				@include media('<=xxs') {
					width: 100%;
					margin-bottom: var(--spaceXs);
				}
			}
		}

		svg {
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
			@include media('<=xxs') {
				width: 1.4em;
			}
		}
	}

	.AppPopup {
		&-title {
			margin: 0 0 1rem;
			padding-left: 0;
			padding-right: 2em;
			color: var(--colorBrand);
		}
	}
} 